@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
  font-family: Montserrat, Jost, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu,
    Cantarell, Fira Sans, Droid Sans, Helvetica Neue, Source Sans Pro, sans-serif;
  // height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
  scrollbar-color: theme('colors.tertiary.500') transparent;
}

a:focus-visible {
  @apply m-1 rounded-3xl p-1 outline outline-1 outline-primary-blue-200;
}

*::-webkit-scrollbar {
  scrollbar-color: theme('colors.tertiary.500') transparent;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: theme('colors.tertiary.600');
  border-radius: 100px;
}

body > div:nth-child(2) {
  display: flex;
  flex-direction: column;
  flex: 1;
}
